import * as React from "react"
import config from "../../src/config"
import { Link } from "gatsby"
import { AiOutlineDownload } from "@react-icons/all-files/ai/AiOutlineDownload"

const DetailsCard = ({ ...props }) => {

  const [cardPortrait, setCardPortrait] = React.useState()
  console.log(props)
  React.useEffect(() => {
    console.log(props);
    if (props) {
      if (props.dataCard) {
        if (props.dataCard.portada) {
          if (props.dataCard.portada.url[0] !== '/') {
            setCardPortrait(props.dataCard.portada.url)
          } else{
            setCardPortrait(`${config.serverUri}${props.dataCard.portada.url}`)
          }
        }
      }
    }
  }, [props])

  const sliceDescription = text => {
    let newDescription
    if (text && text.length > 45) {
      newDescription = `${text.slice(0, 45)}...`
      return newDescription
    } else {
      return text
    }
  }

  return (
    <div>
      {props ? (
        <div
          className="sm:w-96 w-full h-auto rounded-b-2xl shadow-md my-4 mx-2"
          style={{ height: "387px" }}
        >
          <Link
            // state={() => {<WorkoutContentView name={{props}} />}}
            to={`/content/workouts/${props.dataCard.url_slug}`}
          >
            {
              cardPortrait ?
                <img
                  alt={`Image Shift ${props.nombre}, ${props.descripcion}`}
                  className="rounded-t-2xl m-0"
                  src={cardPortrait}
                  style={{ width: "100%", height: "166px", objectFit: "cover" }}
                />
                :
                <img
                  alt={`Image Shift, gana fuerza fisica y mental`}
                  className="rounded-t-2xl m-0"
                  src={`https://images.pexels.com/photos/371049/pexels-photo-371049.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260`}
                  style={{ width: "100%", height: "166px", objectFit: "cover" }}
                />
            }
          </Link>

          <div className="">
            <div className="h-12 flex items-center justify-between border-b-2 border-gray-300 px-4">
              <h4 className="sm:text-base text-xs text-black my-0">
                {props.dataCard.nombre}
              </h4>
              <AiOutlineDownload className="hidden text-5xl w-8 text-green-400" />
            </div>
            <div className="sm:text-base text-xs p-4">
              <div>{sliceDescription(props.dataCard.descripcion)}</div>
              <div className="sm:text-base text-xs text-right pr-8 pt-8">
                <span className="text-green-400 font-bold">Duración: </span>
                <span>
                  {props.dataCard.duracion ? props.dataCard.duracion : "0"}
                </span>
                <span> minutos</span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default DetailsCard
